import "./i18n/i18n";
import "./index.css";

import { Box, Grid } from "@mui/material";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import adeyBackground from "./assets/images/adey_background.png";
import adeyLogo from "./assets/images/adey_logo_white.png";
import { PrimaryButton } from "./components/buttons/PrimaryButton";

/**
 * The error page.
 *
 * @export
 * @class ErrorPage
 * @extends { React.Component<WithTranslation> }
 */
export class ErrorPage extends React.Component<WithTranslation> {
  /**
   * Creates an instance of ErrorPage.
   *
   * @param { {} } props
   * @memberof ErrorPage
   */
  public constructor(props: WithTranslation) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    document.title = this.props.t("error.siteTitle");
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof ErrorPage
   */
  public render(): JSX.Element {
    return (
      <main
        className="h-screen flex items-center justify-center"
        style={{ backgroundColor: "rgb(40, 0, 113)" }}
      >
        <img
          src={adeyBackground}
          className="adey-background z-10 w-full fixed bottom-0 h-3/5	bg-cover bg-no-repeat"
          alt={this.props.t("login.imgAlt.adeyBackground")}
        />
        <Box sx={{ p: "28px", minWidth: "600px" }} className="z-10">
          <div>
            <img
              className="mx-auto mb-5"
              style={{ maxHeight: "55px", width: "auto" }}
              src={adeyLogo}
              alt={this.props.t("dashboard.navigation.brand.imgAlt")}
            />
            <div className="block py-8 px-16 bg-white rounded-lg border border-gray-200 shadow-md w-full">
              <h1 className="text-red-600 font-bold text-center mb-8 text-2xl">
                {this.props.t("error.title")}
              </h1>
              <p className="text-center text-lg mb-10">
                {this.props.t("error.description")}
              </p>
              <Grid container direction="column" spacing="2">
                <PrimaryButton
                  onClick={this.handleClick}
                  text={this.props.t("error.button")}
                  icon="trending_flat"
                  fullWidth
                  type="button"
                ></PrimaryButton>
              </Grid>
            </div>
          </div>
        </Box>
      </main>
    );
  }

  /**
   * Handler method for the navigate home button.
   *
   * Navigates the user back to the root of the main React app.
   *
   * @private
   * @memberof ErrorPage
   */
  private async handleClick(): Promise<void> {
    window.location.href = "/";
  }
}

export default withTranslation()(ErrorPage);
